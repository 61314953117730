import { useState, useRef, Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import { formatDateTime } from "classes/format";
import Axios from "classes/axios";
import UILIB from "components";
import { getYesNo, actionOpenNotesDrawer, tableContainer, setFilterVars } from '../functions';
import { setDrawer } from 'store/actions';
import AddExceptionsDrawer from "../drawer/addExceptionsDrawer";

export default function MIFDrilldownTable({ context, filter, selectedCard }) {
  const defaultDialogue = { show: false };
  const [showDeleteDialogue, setShowDeleteDialogue] = useState(defaultDialogue);
  const [selectedRows, setSelectedRows] = useState({});
  const [renderMe, setRenderMe] = useState(true);
  const [remoteRender, setRemoteRender] = useState(false);
  const [localRender, setLocalRender] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const defaultError = { error: false, message: '' };
  const [bannerError, setBannerError] = useState(defaultError);
  const dispatch = useDispatch();
  const pageContext = context.context;
  const entityData = useRef({});
  const pageSelectedNumber = 50;
  const tablePageDefaults = {
    deleteEnabled: false,
    paging: { pages: [10, 20, 50, 100, 200], pageSelected: pageSelectedNumber, limit: pageSelectedNumber, offset: 0, orderBy: 'custName', orderDir: 'ASC' }
  };
  
  useEffect(() => {
    if (entityData.current.result) {
      const initialSelectedRows = {};
      entityData.current.result.forEach(row => {
        initialSelectedRows[row.id] = selectedRows[row.id] || false;
      });
      setSelectedRows(initialSelectedRows);
    }
  }, [entityData.current.result]);

  const rowSelected = (event, rowId) => {
    const isChecked = event.target.checked;
    setSelectedRows(prevState => ({
      ...prevState,
      [rowId]: isChecked
    }));
  };

  const selectAllRows = (event) => {
    const isChecked = event.target.checked;
    const newSelectedRows = {};

    entityData.current.result.forEach(row => {
      newSelectedRows[row.id] = isChecked;
    });
    setSelectedRows(newSelectedRows);
  };

  const handleAddExceptionClicked = async () => {
    const selectedRowIds = Object.entries(selectedRows)
      .filter(([id, isSelected]) => isSelected)
      .map(([id]) => Number(id))
      .filter((id) => !checkRow(id));

    const selectedEntities = entityData.current.result
    .filter(row => selectedRowIds.includes(row.id))
    .map(({ id, customerId, ...rest }) => ({
      EquipmentId: id,
      CustomerId: customerId,
      ...rest,
    }));

    dispatch(
      setDrawer({
        open: true,
        content: <AddExceptionsDrawer setRemoteRender={setRemoteRender} onClose={exceptionsDrawerClose} selectedEntities={selectedEntities} />,
        width: '700px',
      })
    );
  };

  const handleDeleteExceptionClicked = () => {
    const selectedRowIds = Object.entries(selectedRows)
      .filter(([id, isSelected]) => isSelected)
      .map(([id]) => Number(id))
      .filter((id) => checkRow(id));

    setShowDeleteDialogue({ count: selectedRowIds.length, show: true, exceptionIds: selectedRowIds }); 
  };

  const handleDelete = async (selectedRowIds) => {
    setDeleting(true);

    try {
      await Axios.delete(`/entities/mif_exceptions/mifExceptions/softDelete`, { data: { equipmentIds: selectedRowIds } });
    } catch (error) {
      console.error('Error deleting mif exception', error);
    }

    try {
      await Axios.delete(`/entities/mifNotes/softDelete`, { data: { equipmentIds: selectedRowIds } });
    } catch (error) {
      console.error('Error deleting mif note', error);
    }

    setShowDeleteDialogue(defaultDialogue);
    setDeleting(false);
    setLocalRender(!localRender);
  }

  const exceptionsDrawerClose = async () => {
    dispatch(setDrawer({ open: false, content: null }));
    setRenderMe(!renderMe);
  };

  const constructTable = async (mifData) => {
    const tableData = mifData.map(row => {
      const isChecked = selectedRows[row.id] || false; 
      const b_hasNotes = Boolean(row.notes && row.notes.length && !row.notes.every(note => note.isDeleted));
      const fn_notes = actionOpenNotesDrawer.bind(null, 'ViewNotesDrawer', row, dispatch, true, { get: remoteRender, set: setRemoteRender }, false);
      const orderedNotes = b_hasNotes ? row.notes
        .map(note => ({ ...note, createdAt: new Date(note.createdAt) }))
        .sort((a, b) => b.createdAt - a.createdAt)
        : row.notes;
      let notesColour;
      let notesValue;

      if (!b_hasNotes) {
        notesColour = 'colour lightGrey';
        notesValue = 'null';
      } else if (b_hasNotes && orderedNotes[0].NoteSource === 'Exception') {
        notesColour = 'colour background-6 red';
        notesValue = 'Exception';
      } else {
        notesColour = 'colour background-6 green';
        notesValue = 'Note';
      }

      return {
        rowSelected: { value: <UILIB.Checkbox name="rowSelected" checked={!!isChecked} onChange={(event) => rowSelected(event, row.id)} />, raw: row.id },
        notes: { value: tableContainer(fn_notes, 'icon-pencil', `View Notes - ${notesValue}`, notesColour, true), raw: notesValue },
        serviceRegion: { value: row.serviceRegion, raw: row.serviceRegion },
        accountNo: { value: row.accountNo, raw: row.accountNo },
        custName: { value: row.custName, raw: row.custName },
        groupCode: { value: row.groupCode, raw: row.groupCode },
        SerialNumber: { value: row.SerialNumber, raw: row.SerialNumber },
        description: { value: row.description, raw: row.description },
        location: { value: row.location, raw: row.location },
        postCode: { value: row.postCode, raw: row.postCode },
        installDate: { value: formatDateTime(row.installDate, "DD/MM/YYYY"), raw: row.installDate },
        primaryComms: { value: row.primaryComms, raw: row.primaryComms },                                                                                                         
        primaryCommsDate: { value: formatDateTime(row.primaryCommsDate, "DD/MM/YYYY"), raw: row.primaryCommsDate },
        secondaryComms: { value: row.secondaryComms, raw: row.secondaryComms },
        secondaryCommsDate: { value: formatDateTime(row.secondaryCommsDate, "DD/MM/YYYY"), raw: row.secondaryCommsDate },
        lastCommsDateAny: { value: formatDateTime(row.lastCommsDateAny, "DD/MM/YYYY"), raw: row.lastCommsDateAny },
        monitored: { value: row.monitored, raw: row.monitored },
        customerGrade: { value: row.customerGrade, raw: row.customerGrade },
        collectionMethod: { value: row.collectionMethod, raw: row.collectionMethod },
      };
    });

    return tableData;
  };

  const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken, source) => {
    try {
      const expandArray = (source === 'primary' || source === 'export') ? ['notes'] : [];
      const queryLocal = (query !== null) ? query : '';
      const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
      const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';
      const filterButtons = setFilterVars(pageContext, filter);
      const buttonAppend = filterButtons ? ` ${filterButtons} ` : '';

      entityData.current = await Axios.get(`/entities/mif/getSummaryDrilldown/${pageContext}/?&$filter=${buttonAppend}${queryLocal}&$expand=${expandArray.map(x => x).join(' and ')}${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(api => api.data);

      if (!entityData.current || !entityData.current.result.length) return false;

      return { tableData: await constructTable(entityData.current.result), nonePaged: entityData.current.nonePaged };
    }
    catch (err) {
      console.log(err);
    }
  };

  const selectAllCheckboxColumn = (!Object.values(selectedCard).some(x => x) && filter.button.length > 0)
  ? [{
      label: (
        <UILIB.Checkbox
          onChange={selectAllRows}
          checked={entityData.current.result && entityData.current.result.length > 0 && entityData.current.result.every(row => selectedRows[row.id])}
        />
      ),
      value: "rowSelected",
      align: "left",
      type: 'number'
    }]
  : [];

  const checkRow = (id) => {
    const item = entityData.current.result.find(item => String(item.id) === String(id));
    return item?.notes?.some(note => note.NoteSource === 'Exception');
  };

  const headerData = [
    ...selectAllCheckboxColumn,
    { label: "Notes", value: "notes", type: "string", filtering: false, minWidth: 60, maxWidth: 60, filterArray: getYesNo() },
    { label: "Group Code", value: "groupCode", type: "string", filtering: true, minWidth: 100, maxWidth: 100 },
    { label: "Account #", value: "accountNo", type: "string", filtering: true, minWidth: 120, maxWidth: 120 },
    { label: "Customer Name", value: "custName", type: "string", filtering: true, minWidth: 250, maxWidth: 250 },
    { label: "Device Serial", value: "SerialNumber", type: "string", filtering: true, minWidth: 130, maxWidth: 130 },
    { label: "Device Model", value: "description", type: "string", filtering: true, minWidth: 270, maxWidth: 270 },
    { label: "Location", value: "location", type: "string", filtering: true, minWidth: 180, maxWidth: 180 },
    { label: "Postcode", value: "postCode", type: "string", filtering: true, minWidth: 100, maxWidth: 100 },
    { label: "Installation Date", value: "installDate", type: "date", filtering: true, minWidth: 130, maxWidth: 130 },
    { label: "Last Comms Date Any", value: "lastCommsDateAny", type: "date", filtering: true, minWidth: 130, maxWidth: 130 },
    { label: "Primary Comms Method", value: "primaryComms", type: "string", filtering: true, minWidth: 140, maxWidth: 140 },
    { label: "Last Comms Date", value: "primaryCommsDate", type: "date", filtering: true, minWidth: 120, maxWidth: 120 },
    { label: "Secondary Comms Method", value: "secondaryComms", type: "string", filtering: true, minWidth: 160, maxWidth: 160 },
    { label: "Last Comms Date", value: "secondaryCommsDate", type: "date", filtering: true, minWidth: 120, maxWidth: 120 },
    { label: "Service Region", value: "serviceRegion", type: "string", filtering: true, minWidth: 150, maxWidth: 150 },
    { label: "Device Monitored", value: "monitored", type: "string", filtering: true, minWidth: 120, maxWidth: 120 },
    { label: "Customer Grade", value: "customerGrade", type: "string", filtering: true, minWidth: 100, maxWidth: 100 },
    { label: "Collection Method", value: "collectionMethod", type: "string", filtering: true, minWidth: 100, maxWidth: 100 },
  ];

  return (
    <Fragment>
      {showDeleteDialogue.show && <UILIB.MessageBox 
        header={'Delete Request'} 
        loading={deleting}
        text={`Click OK to DELETE the selected ${(showDeleteDialogue.count) > 1 ? 'Exceptions' : 'Exception'}`} 
        onConfirm={async () => await handleDelete(showDeleteDialogue.exceptionIds)} 
        onCancel={() => setShowDeleteDialogue({ ...showDeleteDialogue, show: false })} 
        errored={bannerError} />}
      <UILIB.Paper className="width-100">
      <div class="flex-container row mar-b10">
        <div class="flex-item flex-grow-1 end wrap">
          {(!Object.values(selectedCard).some(x => x) && filter.button.length > 0) &&
              <UILIB.Button 
                className="button height-100 mar-l5 small red" 
                value='Delete exception' 
                onClick={() => handleDeleteExceptionClicked()}
                disabled={!Object.values(selectedRows).some(value => value) ||
                  !Object.keys(selectedRows).some(id => selectedRows[id] && checkRow(id))}
              />
            }
            {(!Object.values(selectedCard).some(x => x) && filter.button.length > 0) &&
              <UILIB.Button 
                className="button height-100 mar-l5 small green" 
                value='Add exception' 
                onClick={() => handleAddExceptionClicked()}
                disabled={!Object.values(selectedRows).some(value => value) ||
                  !Object.keys(selectedRows).some(id => selectedRows[id] && !checkRow(id))}
              />
            }
          </div>
        </div>
        <UILIB.TableNew
          name={`RMB${pageContext}`}
          className="small"
          overflowX="auto"
          overflowY="hidden"
          header={headerData}
          localQuery={() => constructTable(entityData.current && entityData.current.result ? entityData.current.result : [])}
          localRender={[selectedRows, localRender]}
          remoteQuery={getTableData}
          remoteRender={[filter, remoteRender]}
          defaultSettings={tablePageDefaults} />
      </UILIB.Paper>
    </Fragment>
  );
}
