import { useState, useRef, Fragment } from 'react';
import { checkFormErrors } from "../../../../classes/formErrors/formErrors";
import { getRMBNoteErrors } from "../../../../classes/formErrors/repository";
import { createMultilineTextInput } from 'classes/controls/genericControls';
import { useHistory } from 'react-router-dom';
import UILIB from 'components';
import Axios from 'classes/axios';
import { useDispatch } from 'react-redux';
import { setDrawer } from 'store/actions'
import { getCompanies } from 'classes/helpers';


export default function AddExceptionsDrawer(props) 
{  
    const history = useHistory(); 
    const dispatch = useDispatch();
    const baseData = (history.location && history.location.baseData) ? history.location.baseData : {};
    const emptyEntity = { note: "" };
    const [entityLookup, setEntityLookup] = useState(baseData);
    const [localRender, setLocalRender] = useState(false);
    const [adding, setAdding] = useState(false);
    const [formData, setFormdata] = useState(emptyEntity);
    const [formErrors, setFormErrors] = useState({});
    const [bannerError, setBannerError] = useState({ error: false, message: "" });
    const requestCheckbox = useRef({});
    const postData = useRef({});
    const entityData = useRef({});
    const exceptionError = "There was an exception while saving this record, please reload or contact support";
    const placeholder = "Add your reason here...";
    const className = "consumablesHub siteDevices-notes-textArea";
    const selectedEntities = props.selectedEntities || [];
    const isExceptionsTab = selectedEntities.length === 0;

    const checkAll = () => {
      entityData.current.result.forEach(x =>  { 
        updateCheckbox(x.id, x);
      });
    }

    const selectCheckboxColumn = isExceptionsTab
      ? [{ label: <UILIB.Checkbox onChange={checkAll} />, value: "selected", type: "string", filtering: false, minWidth: 20, maxWidth: 20, align: 'center' }]
      : [{ label: "Account #", value: "accountNo", type: "string", filtering: true, minWidth: 120, maxWidth: 120 }];

    const assetNumberColumn = isExceptionsTab
      ? [{ label: "Asset Number", value: "assetNo", type: "string", filtering: true, minWidth: 120, maxWidth: 120 }]
      : [];

    const headerData = [
      ...selectCheckboxColumn,
      { label: "Serial Number", value: "serialNo", type: "string", filtering: true, minWidth: 120, maxWidth: 120 },
      ...assetNumberColumn,
      { label: "Description", value: "description", type: "string", filtering: true, minWidth: 200, maxWidth: 200 } 
    ];

    const tablePageDefaults = { paging: { limit: 20, offset: 0, orderBy: 'SerialNumber', orderDir: 'DESC' } };

    const handleAddSearch = (data, field) => {
      setEntityLookup({ ...entityLookup, [field]: data.value, customerName: data.customerName });
    }

    const handleAddClicked = async () => { 
        setAdding(true);

        const formResult = await checkFormErrors(getRMBNoteErrors(formData), setFormErrors, null, null, formData, null);

        if (formResult.errorCount === 0) {
          try {
            const selectedEquipment = isExceptionsTab ? Object.values(requestCheckbox.current).filter(equipment => equipment.checked) : selectedEntities;
            await Axios.post(`/entities/mif_exceptions/mifExceptions`, { entity: { selectedEquipment }}).then(api => api.data);
            
            selectedEquipment.forEach((e) => addNote(e));
            setEntityLookup(baseData);
          }
          catch (err) {
            console.log(err);
            setBannerError({ error: true, message: exceptionError });
          }
        }

        setAdding(false);
        props.setRemoteRender(true);

        if(!isExceptionsTab) {
          dispatch(setDrawer({ open: false, content: "" }));
          document.body.style.overflow = 'auto';
        }
    };

    const addNote = async (equipment) => {
      await Axios.post(`/entities/mifNote`, {
        entity: {
          MifId: equipment.EquipmentId,
          SerialNumber: equipment.SerialNumber,
          CustomerId: equipment.CustomerId,
          note: formData.note,
          NoteSource: 'Exception'
        },
      });
    }

    const generateChecked = (entityData) => {
      let checked = {};
      if(entityData && entityData.result) {
        entityData.result.forEach(x =>  { return checked = { ...checked, [x.id]: { checked: false, tonerId: null } } })
        return checked;
      }
    };

    const updateCheckbox = (id, row) => {
        const checked = !requestCheckbox.current[id].checked;
        const equipmentID = Number(row.id);
        const serialNumber = String(row.SerialNumber);
        const assetNumber = String(row.assetNumber);
        const description = String(row.description);
        const customerId = String(row.customerId);

        requestCheckbox.current = { ...requestCheckbox.current, [id]: { checked: checked, EquipmentId: equipmentID, SerialNumber: serialNumber, assetNumber, description, CustomerId: customerId } };

        if(checked) {
            postData.current.equipment = { ...postData.current.equipment, ...requestCheckbox.current };

            Object.keys(postData.current.equipment).forEach(x => {
              if(postData.current.equipment[x].serialNumber === serialNumber) postData.current.equipment[x].failedRequestId = id });
        } else {
            Object.keys(postData.current.equipment).forEach(x => {
              if(postData.current.equipment[x].serialNumber === serialNumber) delete postData.current.equipment[x] });
        }

        setLocalRender(!localRender);
    };

    async function constructTable(apiData) {

      const tableData = apiData.map(row => {

        return {
          ...(isExceptionsTab
            ? {
                selected: {
                  value: (
                    <UILIB.TableContainer
                      data={
                        <UILIB.Checkbox
                          checked={requestCheckbox.current[row.id].checked}
                          type="checkbox"
                          onChange={() => updateCheckbox(row.id, row)}
                        />
                      }
                    />
                  ),
                },
              }
            : {
                accountNo: { value: row.accountNo, raw: row.accountNo },
              }),
          serialNo: { value: row.SerialNumber, raw: row.SerialNumber },
          assetNo: { value: row.assetNumber, raw: row.assetNumber },
          description: { value: row.description, raw: row.description }
        };
      });
      return tableData;
    }

    const getTableData = async (query, limit, offset, orderBy, orderDir, cancelToken) => {
      if(!isExceptionsTab) {
        return { tableData: await constructTable(selectedEntities), raw: selectedEntities, nonePaged: entityData.current.nonePaged };
      }

      const queryLocal = (query !== null) ? query : '';
      const pagingLocal = (limit !== null && offset !== null) ? `&$limit=${limit}&$offset=${offset}` : '';
      const orderLocal = (orderBy !== null && orderDir !== null) ? `&$order=${orderBy}&$direction=${orderDir}` : '';

      entityData.current = await Axios.get(`/entities/equipments/getSummaryRMBException/${entityLookup.customerId}/?&$filter=${queryLocal} ${pagingLocal}${orderLocal}`, { cancelToken: cancelToken.token }).then(api => api.data);

      requestCheckbox.current = generateChecked(entityData.current);

      return { tableData: await constructTable(entityData.current.result), raw: entityData.current.result, nonePaged: entityData.current.nonePaged }
    }

    const actionUpdateNotes = async (event) => {
      setFormdata({ ...formData, [event.target.name]: event.target.value });
    };

    return <Fragment>
        <h2>Add Exceptions</h2>
        {isExceptionsTab && (
          <UILIB.Paper className='flex-item grey flex-grow-1 nowrap mar-0'>
              <div className='flex-item align-center start flex-grow-1'>Search for a Customer</div>
              <UILIB.AutoComplete
                className="min-height-50"
                name='customerId'
                blank='No Customers Found'
                query={getCompanies}
                enableButton={true}
                onClick={(data) => handleAddSearch(data, 'customerId')} />
          </UILIB.Paper>
        )}
        {(entityLookup.hasOwnProperty('customerId') || !isExceptionsTab) && <div className='width-100 mar-t15'>
            <UILIB.TableNew
                name="EquipmentDetails"
                className='small'
                overflowX='auto'
                overflowY='hidden'    
                header={headerData}
                remoteQuery={getTableData}
                localQuery={() => constructTable((entityData.current && entityData.current.result) ? entityData.current.result : [])}
                localRender={[localRender]}
                remoteRender={[entityLookup.customerId]}
                defaultSettings={tablePageDefaults} />
            {(postData.current.equipment || !isExceptionsTab) &&
              <div>Reason / Note
                {createMultilineTextInput(
                  "",
                  "note",
                  placeholder,
                  true,
                  formData,
                  formErrors,
                  (ev) => actionUpdateNotes(ev),
                  false,
                  className,
                  true
                )}
              </div>}
              {bannerError.error && (
                <div className="flex-container end width-100">
                  <div className="errored message">{bannerError.message}</div>
                </div>
              )}
            <UILIB.Button loading={adding} disabled={adding} className="primary mar-5" value="Add" onClick={() => handleAddClicked()} />
        </div>}
  </Fragment>
}